import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt } from '../types';

export const rfqRoutes: RouteExt = {
  path: 'rfq/create',
  component: LayoutDefaultComponent,
  data: {
    title: 'rfqPage.title',
    isBigHeader: true,
    seo: {
      translateKey: 'rfqBuyPage',
      robotsDenied: true
    },
    breadcrumbs: [Crumbs.Home]
  },
  resolve: { seoData: seoResolver },
  loadChildren: () =>
    import(/* webpackPrefetch: true */ '@app/rfq/rfq-create/rfq-create.module').then((module) => module.RfqCreateModule)
};
