import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, PLATFORM_ID, TransferState } from '@angular/core';
import { TranslateServerLoader } from './translate-server.loader';
import TranslateBrowserLoader from './translate-browser.loader';

export function translateLoaderFactory(
  httpClient: HttpClient,
  transferState: TransferState,
  doc: Document
): TranslateBrowserLoader | TranslateServerLoader {
  const platformId = inject(PLATFORM_ID);

  if (isPlatformBrowser(platformId)) {
    return new TranslateBrowserLoader(transferState, httpClient);
  } else {
    return new TranslateServerLoader(transferState, doc);
  }
}
