import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt, Sections } from '../types';

export const selfHelpRoutes: RouteExt = {
  path: 'self-help',
  component: LayoutDefaultComponent,
  resolve: { seoData: seoResolver },
  data: {
    title: 'selfHelpPage.title',
    section: Sections.SelfHelp,
    isBigHeader: true,
    seo: {
      translateKey: 'selfHelpPage',
      canonical: true,
      manualSetTexts: true
    },
    breadcrumbs: [Crumbs.Home]
  },
  children: [
    {
      path: '',
      loadChildren: () =>
        import(/* webpackPrefetch: true */ '@app/search/search.module').then((module) => module.SearchModule)
    }
  ]
};
