import { isPlatformServer } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { UserService } from './user.service';
import { HttpService } from './http.service';
import { ApiMethods } from '../types';
import { ActivityRequest, ActivityResult } from '../models/api';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private userService: UserService,
    private httpService: HttpService
  ) {}

  sendData(url: string | null) {
    if (isPlatformServer(this.platformId) || !this.userService.isClient) return;

    const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform || 'unknown';
    const payload = {
      userId: this.userService.user?.id || 0,
      role: this.userService.role,
      page: url || window.location.href,
      browser: '[' + navigator?.vendor + '] ' + navigator.userAgent,
      os: platform
    };
    this.httpService
      .post<ActivityRequest, ActivityResult>({ method: ApiMethods.Activity }, payload)
      .subscribe(() => {});
  }
}
