import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt, SchemaOrgType } from '../types';

export const staticRoutes: RouteExt[] = [
  {
    path: 'contact-us',
    component: LayoutDefaultComponent,
    data: {
      title: 'contactUsPage.title',
      seo: {
        translateKey: 'contactUsPage',
        schemaOrgKey: SchemaOrgType.ContactPage,
        canonical: true
      },
      breadcrumbs: [Crumbs.Home]
    },
    resolve: { seoData: seoResolver },
    children: [
      {
        path: '',
        loadComponent: () => import('@app/contact-us/contact-us.component').then((module) => module.ContactUsComponent)
      }
    ]
  },
  {
    path: 'about-us',
    component: LayoutDefaultComponent,
    data: {
      title: 'aboutUsPage.title',
      seo: {
        translateKey: 'aboutUsPage',
        schemaOrgKey: SchemaOrgType.AboutPage,
        canonical: true
      },
      breadcrumbs: [Crumbs.Home]
    },
    resolve: { seoData: seoResolver },
    children: [
      {
        path: '',
        loadComponent: () => import('@app/about-us/about-us.component').then((module) => module.AboutUsComponent)
      }
    ]
  },
  {
    path: 'terms',
    loadChildren: () => import('@app/terms-of-use/terms-of-use.module').then((module) => module.TermsOfUseModule)
  },
  {
    path: 'privacy-policy',
    component: LayoutDefaultComponent,
    data: {
      title: 'privacyPolicyPage.title',
      seo: {
        translateKey: 'otherPages',
        schemaOrgKey: SchemaOrgType.WebPage,
        canonical: true,
        robotsDenied: true
      },
      breadcrumbs: [Crumbs.Home]
    },
    resolve: { seoData: seoResolver },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@app/privacy-policy/privacy-policy.component').then((module) => module.PrivacyPoliceComponent)
      }
    ]
  },
  {
    path: 'how-it-works',
    children: [
      {
        path: '',
        loadChildren: () => import('@app/how-it-works/how-it-works.module').then((module) => module.HowItWorkModule)
      }
    ]
  },
  {
    path: 'for-lawyers',
    component: LayoutDefaultComponent,
    data: {
      title: 'forLawyersPage.title',
      seo: {
        translateKey: 'forLawyersPage',
        schemaOrgKey: SchemaOrgType.WebPage,
        canonical: true
      },
      breadcrumbs: [Crumbs.Home]
    },
    resolve: { seoData: seoResolver },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@app/for-lawyers/for-lawyers.component').then((module) => module.ForLawyersComponent)
      }
    ]
  },
  {
    path: 'payment-terms',
    component: LayoutDefaultComponent,
    data: {
      title: 'paymentTermsPage.title',
      seo: {
        translateKey: 'termsOfUsePage',
        schemaOrgKey: SchemaOrgType.WebPage,
        canonical: true,
        robotsDenied: true
      },
      breadcrumbs: [Crumbs.Home]
    },
    resolve: { seoData: seoResolver },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@app/payment-terms/payment-terms.component').then((module) => module.PaymentTermsComponent)
      }
    ]
  }
];
