import { DOCUMENT, isPlatformBrowser, ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  ViewChild,
  Component,
  Inject,
  OnInit,
  Renderer2,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  afterNextRender,
  NgZone,
  inject,
  PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, debounceTime, delay, distinctUntilKeyChanged, filter, fromEvent, take } from 'rxjs';
import { AppSettingsService, BreakpointsService, SocketsService } from './shared/services';
import { GtagService } from './shared/services/gtag.service';
import { SeoService } from './shared/services/seo.service';
import { ActivityService } from './shared/services/activity.service';
import { ModalComponent } from './shared/components/modal/modal.component';
import { LayoutDefaultComponent } from './shared/layouts';
import {} from '@angular/common/http';
import { LawyerListComponent } from '@comp/lawyer';
import { SharedModule } from './shared';
import { SetPasswordFormComponent } from '@comp/set-password-form/set-password-form.component';
import { ModalServiceComponent } from '@comp/modal/modal-service/modal-service.component';

const HEADER_HEIGHT = 54;

@Component({
  selector: 'ln-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LawyerListComponent, ModalComponent, ModalServiceComponent, SharedModule, SetPasswordFormComponent]
})
export class AppComponent implements AfterViewInit, OnInit {
  isLoading = false;
  isShowSetPassword?: boolean;
  expandedList = false;
  listTop?: number | null = null;
  originalListTop?: number;
  isMobile?: boolean;
  expandedListSaved = this.expandedList;

  @ViewChild('setPasswordModal') setPasswordModalRef!: ModalComponent;

  constructor(
    private activityService: ActivityService,
    private gtagService: GtagService,
    private renderer: Renderer2,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private seo: SeoService,
    protected settings: AppSettingsService,
    private viewportScroller: ViewportScroller,
    private el: ElementRef,
    private breakpoints: BreakpointsService,
    private cdr: ChangeDetectorRef,
    private socketsService: SocketsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc?: Document
  ) {
    inject(NgZone).runOutsideAngular(() => {
      setTimeout(() => {
        this.gtagService.event('Time on page more than minute', {
          'event_category': 'Profitable Engagement'
        });
      }, 60 * 1000);
    });

    afterNextRender(() => {
      this.viewportScroller.setOffset([0, 54]);

      this.breakpoints.currentBreakpoint$?.subscribe((bp) => {
        this.isMobile = ['xs', 'sm', 'md'].includes(bp.breakPoint);
        if (!this.isMobile) this.expandedList = bp.width > 1900;
        this.expandedListSaved = this.expandedList;

        this.calcPositionList();
        this.cdr.detectChanges();
      });

      fromEvent(window, 'pagehide').subscribe((e) => {
        this.socketsService.disconnectRefresher();
        this.socketsService.disconnectStatistic();
      });

      fromEvent(window, 'pageshow').subscribe((e) => {
        if ('persisted' in e && e.persisted) {
          this.socketsService.connectRefresher();
        }
      });

      this.activityService.sendData(null);
      this.router.events
        .pipe(
          filter((e: any) => e instanceof NavigationEnd),
          distinctUntilKeyChanged('url')
        )
        .subscribe((e) => {
          this.calcPositionList();
          this.activityService.sendData(e.url);
        });

      this.settings.settings$
        .pipe(
          filter((option) => option.needPassword),
          take(1)
        )
        .subscribe((needPassword) => {
          this.isShowSetPassword = true;
          setTimeout(() => this.setPasswordModalRef.open(), 50);
          this.cdr.detectChanges();
        });

      fromEvent(document, 'scroll').subscribe(() => {
        if (typeof this.listTop !== 'number') return;
        this.calcPositionTop();
      });

      this.router.events
        .pipe(
          filter((e): e is Scroll => e instanceof Scroll),
          delay(300)
        )
        .subscribe((e) => {
          if (e.position) {
            this.viewportScroller.scrollToPosition(e.position || [0, 0]);
          } else if (e.anchor) {
            this.viewportScroller.scrollToAnchor(e.anchor || '');
          }
        });
    });

    const findLayoutRoute = (currentRoute: ActivatedRoute): ActivatedRoute | undefined => {
      if (currentRoute.snapshot.component === LayoutDefaultComponent) return currentRoute;
      else if (currentRoute.children.length) return findLayoutRoute(currentRoute.children[0]);
      else return undefined;
    };

    if (!!findLayoutRoute(this.route)) {
      this.originalListTop = 320 - 73; // default position for server side rendering
      this.listTop = this.originalListTop;
    }
  }

  ngOnInit() {
    this.seo.setCopyright();
    this.seo.setSocial();
    this.seo.setOG();
    this.seo.setTwitterData();

    this.gtagService.config({ 'allow_display_features': true, 'link_attribution': true });
    this.gtagService.configAdwords();
  }

  ngAfterViewInit() {
    this.setLanguageTags(this.translate.currentLang);

    this.translate.onLangChange.subscribe((lang) => {
      this.isLoading = true;
      this.setLanguageTags(lang.lang);
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          debounceTime(50),
          take(1)
        )
        .subscribe((event) => {
          window.location.reload();
        });
    });
    this.calcPositionList();
  }

  calcPositionTop() {
    const [, y] = this.viewportScroller.getScrollPosition();
    if (y < (this?.originalListTop || 0) - HEADER_HEIGHT) this.listTop = -y + (this?.originalListTop || 0);
    else this.listTop = HEADER_HEIGHT;
  }

  calcPositionList() {
    if (!this.isMobile && isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        const topRef = this.el?.nativeElement.querySelector('.default-layout__top');
        const bodyRef = this.el?.nativeElement.querySelector(
          topRef ? '.default-layout__main' : '.layout-default__body'
        );
        if (bodyRef) {
          this.originalListTop = Math.max(bodyRef.getBoundingClientRect().top + window.scrollY, 0);
          this.calcPositionTop();
        } else this.listTop = null;
        this.cdr.markForCheck();
      }, 1);
    }
  }

  setLanguageTags(lang: string) {
    const dir = this.translate.instant('global.direction');

    const htmlElement = this.doc?.getElementsByTagName('html')[0];
    if (!htmlElement) return;

    htmlElement.removeAttribute('dir');
    htmlElement.setAttribute('dir', dir);

    htmlElement.removeAttribute('lang');
    htmlElement.setAttribute('lang', lang);
  }

  onPasswordChanged() {
    this.setPasswordModalRef.close();
    this.settings.setNeedPassword(false);
  }

  onMouseLeaveLawyerList() {
    if (this.expandedList) this.expandedList = this.expandedListSaved;
    this.cdr.detectChanges();
  }

  onMoseOverLawyerList() {
    if (!this.expandedList) this.expandedList = true;
    this.cdr.detectChanges();
  }
}
