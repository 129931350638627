import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt, Sections } from '../types';
import { matcherId } from '@shared/utils';

export const companyBlogRoutes: RouteExt = {
  path: 'company-blog',
  children: [
    {
      path: '',
      component: LayoutDefaultComponent,
      data: {
        title: 'companyBlogPage.title',
        isBigHeader: true
      },
      children: [
        {
          path: '',
          data: {
            section: Sections.CompanyBlog,
            seo: {
              translateKey: 'companyBlogPage',
              canonical: true
            },
            breadcrumbs: [Crumbs.Home]
          },
          resolve: { seoData: seoResolver },
          loadChildren: () => import('@app/search/search.module').then((module) => module.SearchModule)
        }
      ]
    },
    {
      path: ':id/:alias',
      component: LayoutDefaultComponent,
      data: {
        title: 'companyBlogPage.title',
        titleH2: true
      },
      children: [
        {
          path: '',
          loadComponent: () => import('@app/entity/entity.component').then((module) => module.EntityComponent),
          data: {
            section: Sections.CompanyBlog,
            seo: {
              translateKey: null,
              disableAutoSetLangs: true
            },
            breadcrumbs: [Crumbs.Home, Crumbs.CompanyBlog]
          },
          resolve: { seoData: seoResolver },
          children: [
            {
              path: '',
              loadChildren: () =>
                import('@app/entity/company-blog/company-blog.module').then((module) => module.CompanyBlogModule)
            }
          ]
        }
      ]
    },
    {
      matcher: (url) => matcherId(url),
      redirectTo: ':id/',
      pathMatch: 'full'
    }
  ]
};
