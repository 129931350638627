import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt, Sections } from '../types';
import { matcherId } from '@shared/utils';

export const legalBlogRoutes: RouteExt = {
  path: 'legal-blog',
  children: [
    {
      path: '',
      component: LayoutDefaultComponent,
      data: {
        title: 'legalBlogPage.title',
        isBigHeader: true
      },
      children: [
        {
          path: '',
          data: {
            section: Sections.LegalBlog,
            seo: {
              translateKey: 'legalBlogPage',
              canonical: true,
              manualSetTexts: true
            },
            breadcrumbs: [Crumbs.Home]
          },
          resolve: { seoData: seoResolver },
          loadChildren: () => import('@app/search/search.module').then((module) => module.SearchModule)
        }
      ]
    },
    {
      path: ':id/:alias',
      component: LayoutDefaultComponent,
      data: {
        title: 'legalBlogPage.title',
        titleH2: true
      },
      children: [
        {
          path: '',
          loadComponent: () => import('@app/entity/entity.component').then((module) => module.EntityComponent),
          data: {
            section: Sections.LegalBlog,
            seo: {
              translateKey: null,
              disableAutoSetLangs: true
            },
            breadcrumbs: [Crumbs.Home, Crumbs.LegalBlog]
          },
          resolve: { seoData: seoResolver },
          children: [
            {
              path: '',
              loadChildren: () =>
                import('@app/entity/legal-blog/legal-blog.module').then((module) => module.LegalBlogModule)
            }
          ]
        }
      ]
    },
    {
      matcher: (url) => matcherId(url),
      redirectTo: ':id/',
      pathMatch: 'full'
    }
  ]
};
