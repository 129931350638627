import { LayoutEmptyComponent } from '../layouts';
import { RouteExt } from '../types';

export const othersRoutes: RouteExt[] = [
  {
    path: 'reset/password',
    component: LayoutEmptyComponent,
    data: {
      isFullScreen: true,
      seo: {
        robotsDenied: true
      }
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@app/reset-password/reset-password.module').then((module) => module.ResetPasswordModule)
      },
      {
        path: ':hash',
        loadChildren: () =>
          import('@app/reset-password/reset-password.module').then((module) => module.ResetPasswordModule)
      }
    ]
  },
  {
    path: 'login',
    component: LayoutEmptyComponent,
    data: {
      isFullScreen: true,
      seo: {
        robotsDenied: true
      }
    },
    children: [
      {
        path: '',
        loadChildren: () => import('@app/login/login.module').then((module) => module.LoginModule)
      }
    ]
  },
  {
    path: 'hash',
    component: LayoutEmptyComponent,
    data: {
      isFullScreen: true,
      seo: {
        robotsDenied: true
      }
    },
    children: [
      {
        path: ':hash',
        loadChildren: () => import('@app/hash/hash.module').then((module) => module.HashModule)
      }
    ]
  },
  {
    path: '404',
    component: LayoutEmptyComponent,
    data: {
      isFullScreen: true
    },
    children: [
      {
        path: '',
        data: {
          errorCode: '404'
        },
        loadChildren: () => import('@app/error/error.module').then((module) => module.ErrorModule)
      }
    ]
  },
  {
    path: '500',
    component: LayoutEmptyComponent,
    data: {
      isFullScreen: true
    },
    children: [
      {
        path: '',
        data: {
          errorCode: '500'
        },
        loadChildren: () => import('@app/error/error.module').then((module) => module.ErrorModule)
      }
    ]
  }
];
