import { Observable, of } from 'rxjs';
import { TranslateLoader, TranslationObject } from '@ngx-translate/core';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/core';
import { STATE_TRANSLATE } from '@shared/const/i18n';

export default class TranslateBrowserLoader implements TranslateLoader {
  constructor(
    private transferState: TransferState,
    private http: HttpClient,
    private prefix: string = './i18n/',
    private suffix: string = `.json`
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const key = makeStateKey<TranslationObject>(STATE_TRANSLATE + lang);
    const data = this.transferState.get(key, null);
    return data ? of(data) : new TranslateHttpLoader(this.http, this.prefix, this.suffix).getTranslation(lang);
  }
}
