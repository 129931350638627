import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt, Sections } from '../types';
import { matcherCountry, matcherId } from '../utils';

export const questionsRoutes: RouteExt = {
  path: 'questions',
  children: [
    {
      matcher: (url) => matcherCountry(url),
      component: LayoutDefaultComponent,
      data: {
        title: 'questionPage.title',
        button: { label: 'questionPage.buttonCreateQuestions', link: '/ask-a-lawyer' },
        isBigHeader: true
      },
      children: [
        {
          path: '',
          data: {
            section: Sections.Questions,
            seo: {
              translateKey: 'questionPage',
              canonical: true,
              manualSetTexts: true
            },
            breadcrumbs: [Crumbs.Home]
          },
          resolve: { seoData: seoResolver },
          loadChildren: () =>
            import(/* webpackPrefetch: true */ '@app/search/search.module').then((module) => module.SearchModule)
        }
      ]
    },
    {
      path: ':id/:alias',
      component: LayoutDefaultComponent,
      data: {
        title: 'questionPage.title',
        button: { label: 'questionPage.buttonCreateQuestions', link: '/ask-a-lawyer' },
        titleH2: true
      },
      children: [
        {
          path: '',
          loadComponent: () => import('@app/entity/entity.component').then((module) => module.EntityComponent),
          data: {
            section: Sections.Questions,
            seo: {
              translateKey: null,
              disableAutoSetLangs: true
            },
            breadcrumbs: [Crumbs.Home, Crumbs.Questions]
          },
          resolve: { seoData: seoResolver },
          children: [
            {
              path: '',
              loadChildren: () => import('@app/entity/question/question.module').then((module) => module.QuestionModule)
            }
          ]
        }
      ]
    },
    {
      matcher: (url) => matcherId(url),
      redirectTo: ':id/',
      pathMatch: 'full'
    }
  ]
};
