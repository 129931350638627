import { LocalizeParser, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { Routes } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { StateKey, makeStateKey, TransferState } from '@angular/core';

export class LocalizeBrowserLoader extends LocalizeParser {
  private translateService: TranslateService;
  private LocalLocation: Location;
  private localizeRouterSettings: LocalizeRouterSettings;

  constructor(
    translateService: TranslateService,
    location: Location,
    settings: LocalizeRouterSettings,
    private data: any
  ) {
    super(translateService, location, settings);
    this.translateService = translateService;
    this.LocalLocation = location;

    this.localizeRouterSettings = settings;
  }

  public load(routes: Routes): Promise<any> {
    return new Promise((resolve: any) => {
      this.locales = this.data.locales;
      this.prefix = this.data.prefix;

      this.translateService.addLangs(this.locales);
      this.escapePrefix = '';
      this.init(routes).then(resolve);
    });
  }
}

export class LocalizeSPALoader extends LocalizeParser {
  private translateService: TranslateService;
  private LocalLocation: Location;
  private localizeRouterSettings: LocalizeRouterSettings;

  constructor(
    private httpClient: HttpClient,
    translateService: TranslateService,
    location: Location,
    settings: LocalizeRouterSettings
  ) {
    super(translateService, location, settings);
    this.translateService = translateService;
    this.LocalLocation = location;
    this.localizeRouterSettings = settings;
  }

  public load(routes: Routes): Promise<any> {
    return new Promise((resolve: any) => {
      this.httpClient.get('i18n/locales.json').subscribe((data: any) => {
        this.locales = data.locales;
        this.translateService.addLangs(this.locales);
        this.escapePrefix = '';
        this.init(routes).then(resolve);
      });
    });
  }
}

export function localizeBrowserLoaderFactory(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  httpClient: HttpClient,
  transferState: TransferState
): LocalizeParser {
  const key: StateKey<number> = makeStateKey<number>('transfer-locales');

  const data = transferState.get(key, null);
  if (data) {
    return new LocalizeBrowserLoader(translate, location, settings, data);
  } else {
    return new LocalizeSPALoader(httpClient, translate, location, settings);
  }
}
