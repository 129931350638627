import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt, Sections } from '../types';
import { entityResolver } from '@shared/services/entity-resolver.service';

export const searchRoutes: RouteExt = {
  path: 'search',
  component: LayoutDefaultComponent,
  resolve: { entityData: entityResolver, seoData: seoResolver },
  data: {
    title: 'searchPage.title',
    section: Sections.SelfHelp,
    isBigHeader: true,
    seo: {
      translateKey: 'searchPage',
      canonical: false,
      robotsDenied: true,
      manualSetTexts: true
    },
    breadcrumbs: [Crumbs.Home]
  },
  children: [
    {
      path: '',
      loadChildren: () =>
        import(/* webpackPrefetch: true */ '@app/search/search.module').then((module) => module.SearchModule)
    }
  ]
};
