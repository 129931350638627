import { ApplicationConfig, TransferState, importProvidersFrom } from '@angular/core';
import { provideRouter, withDisabledInitialNavigation, withInMemoryScrolling, withRouterConfig } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideTranslateService, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { SharedModule } from './shared';
import { DOCUMENT, Location } from '@angular/common';
import {
  CacheMechanism,
  LocalizeParser,
  LocalizeRouterSettings,
  withLocalizeRouter
} from '@gilsdav/ngx-translate-router';
import { localizeBrowserLoaderFactory } from '@shared/loaders/localize-browser.loader';
import { provideWebsocket } from '@shared/services/websocket/websocket.providers';
import { isProtectedUrl } from '@shared/const/api/protected';
import { translateLoaderFactory } from '@shared/loaders/translate-loader-factory';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch()),
    importProvidersFrom([SharedModule.forRoot()]),
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient, TransferState, DOCUMENT]
      }
    }),
    provideRouter(
      routes,
      withDisabledInitialNavigation(),
      withRouterConfig({
        onSameUrlNavigation: 'reload'
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      }),
      withLocalizeRouter(routes, {
        parser: {
          provide: LocalizeParser,
          useFactory: localizeBrowserLoaderFactory,
          deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient, TransferState]
        },
        initialNavigation: true,
        alwaysSetPrefix: false,
        cacheMechanism: CacheMechanism.Cookie,
        useCachedLang: false
      })
    ),
    provideClientHydration(
      withHttpTransferCacheOptions({
        filter: (res) => {
          return !isProtectedUrl(res.url);
        }
      })
    ),
    provideWebsocket()
  ]
};
