import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt } from '../types';

export const getQuotesRoutes: RouteExt = {
  path: 'get-quotes',
  component: LayoutDefaultComponent,
  data: {
    title: 'getQuotesPage.title',
    titleH2: true,
    seo: {
      translateKey: 'getQuotesPage',
      canonical: true
    },
    breadcrumbs: [Crumbs.Home]
  },
  resolve: { seoData: seoResolver },
  children: [
    {
      path: '',
      loadChildren: () =>
        import(/* webpackPrefetch: true */ '@app/get-quotes/get-quotes.module').then((module) => module.GetQuotesModule)
    }
  ]
};
