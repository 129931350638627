import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt, Sections } from '../types';

export const testimonialsRoutes: RouteExt = {
  path: 'testimonials',
  component: LayoutDefaultComponent,
  data: {
    title: 'testimonialsPage.title',
    section: Sections.Testimonials,
    isBigHeader: true,
    seo: {
      translateKey: 'testimonialsPage',
      canonical: true,
      manualSetTexts: true
    },
    breadcrumbs: [Crumbs.Home]
  },
  resolve: { seoData: seoResolver },
  children: [
    {
      path: '',
      loadChildren: () =>
        import(/* webpackPrefetch: true */ '@app/testimonials/testimonials.module').then(
          (module) => module.TestimonialsModule
        )
    }
  ]
};
