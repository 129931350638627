import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { WebsocketService } from './websocket.service';
import { WebSocketConfig } from './websocket.types';
import { WEBSOCKET_CONFIG } from './websocket.config';

export function provideWebsocket(config?: Partial<WebSocketConfig>): EnvironmentProviders {
  return makeEnvironmentProviders([
    WebsocketService,
    {
      provide: WEBSOCKET_CONFIG,
      useValue: config
    }
  ]);
}
