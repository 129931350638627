import { RouteExt } from '../types';

export const servicesRoutes: RouteExt = {
  path: 'services',
  children: [
    {
      path: '',
      loadChildren: () =>
        import(/* webpackPrefetch: true */ '@app/services/services.module').then((module) => module.ServicesModule)
    }
  ]
};
