import { LayoutEmptyComponent } from '@shared/layouts';
import { RouteExt, SignInProvider } from '../types';

export const callbacksRoutes: RouteExt = {
  path: 'callback',
  children: [
    {
      path: SignInProvider.Linkedin,
      component: LayoutEmptyComponent
    },
    {
      path: SignInProvider.Google,
      component: LayoutEmptyComponent
    },
    {
      path: SignInProvider.Facebook,
      component: LayoutEmptyComponent
    }
  ]
};
