import { seoResolver } from '@shared/services/seo-resolver.service';
import {
  askALawyerRoutes,
  clientRoutes,
  companyBlogRoutes,
  getQuotesRoutes,
  lawFirmsRoutes,
  lawyersRoutes,
  legalBlogRoutes,
  legalDictionaryRoutes,
  legislationRoutes,
  othersRoutes,
  questionsRoutes,
  rfqRoutes,
  searchRoutes,
  selfHelpRoutes,
  servicesRoutes,
  staticRoutes,
  testimonialsRoutes
} from './shared/routing';
import { RouteExt, SchemaOrgType } from './shared/types';
import { callbacksRoutes } from '@shared/routing/callbacks';
import { LayoutEmptyComponent } from '@shared/layouts';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { inject } from '@angular/core';

export const routes: RouteExt[] = [
  {
    path: '',
    pathMatch: 'full',
    component: LayoutEmptyComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@app/home/home.module').then((module) => module.HomeModule),
        resolve: { seoData: seoResolver },
        data: {
          seo: {
            translateKey: 'home',
            schemaOrgKey: SchemaOrgType.WebSite,
            canonical: true
          }
        }
      }
    ]
  },
  questionsRoutes,
  askALawyerRoutes,
  selfHelpRoutes,
  searchRoutes,
  lawyersRoutes,
  lawFirmsRoutes,
  legalBlogRoutes,
  companyBlogRoutes,
  legislationRoutes,
  legalDictionaryRoutes,
  servicesRoutes,
  testimonialsRoutes,
  getQuotesRoutes,
  rfqRoutes,
  clientRoutes,
  callbacksRoutes,
  ...staticRoutes,
  ...othersRoutes,
  {
    path: '**',
    redirectTo: () => {
      const localizeRouterService = inject(LocalizeRouterService);
      return localizeRouterService.translateRoute('/404') as string;
    }
  }
  // { path: '**', redirectTo: '/404' }
];
