<router-outlet *ngIf="!isLoading"></router-outlet>
@if (isShowSetPassword) {
  <ln-modal
    #setPasswordModal
    [header]="'home.setPassword.title' | translate"
    (closed)="isShowSetPassword = false"
    [style]="{ minHeight: 'auto' }"
  >
    <ng-container body>
      <div [style]="{ margin: 'calc(-1 * var(--mrg-col))' }">
        <div class="common-text blocks__block">{{ 'home.setPassword.description' | translate }}</div>
        @defer (on idle) {
          <ln-set-password-form [isChangePassword]="false" (changed)="onPasswordChanged()"></ln-set-password-form>
        }
      </div>
    </ng-container>
  </ln-modal>
}
@defer (on idle) {
  <ln-lawyer-list
    [expanded]="expandedList"
    (mouseOver)="onMoseOverLawyerList()"
    (mouseLeave)="onMouseLeaveLawyerList()"
    *ngIf="this.listTop !== null && this.listTop !== undefined && !isMobile"
    [style]="{ top: listTop + 'px' }"
  ></ln-lawyer-list>
}
@defer (on idle) {
  <ln-modal-service></ln-modal-service>
}
