import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt, Sections } from '../types';
import { matcherId } from '@shared/utils';

export const legalDictionaryRoutes: RouteExt = {
  path: 'legal-dictionary',
  children: [
    {
      path: '',
      component: LayoutDefaultComponent,
      data: {
        title: 'legalDictionaryPage.title',
        isBigHeader: true
      },
      children: [
        {
          path: '',
          data: {
            section: Sections.LegalDictionary,
            ignoreSaveCountry: true,
            seo: {
              translateKey: 'legalDictionaryPage',
              canonical: true
            },
            breadcrumbs: [Crumbs.Home]
          },
          resolve: { seoData: seoResolver },
          loadChildren: () => import('@app/search/search.module').then((module) => module.SearchModule)
        }
      ]
    },
    {
      path: ':id/:alias',
      component: LayoutDefaultComponent,
      data: {
        title: 'legalDictionaryPage.title',
        titleH2: true
      },
      children: [
        {
          path: '',
          loadComponent: () => import('@app/entity/entity.component').then((module) => module.EntityComponent),
          data: {
            section: Sections.LegalDictionary,
            seo: {
              translateKey: null,
              disableAutoSetLangs: true
            },
            breadcrumbs: [Crumbs.Home, Crumbs.Terms]
          },
          resolve: { seoData: seoResolver },
          children: [
            {
              path: '',
              loadChildren: () =>
                import('@app/entity/legal-dictionary/legal-dictionary.module').then(
                  (module) => module.LegalDictionaryModule
                )
            }
          ]
        }
      ]
    },
    {
      matcher: (url) => matcherId(url),
      redirectTo: ':id/',
      pathMatch: 'full'
    }
  ]
};
