import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, EntityType, RouteExt } from '../types';

export const clientRoutes: RouteExt = {
  path: 'client',
  children: [
    {
      path: 'orders',
      loadChildren: () => import('@app/client/order/routes/orders.routes').then((module) => module.ROUTERS_ORDERS)
    },
    {
      path: 'questions',
      loadChildren: () =>
        import('@app/client/order/routes/questions.routes').then((module) =>
          module.ROUTERS_QUESTIONS(EntityType.Question)
        )
    },
    {
      path: 'callbacks',
      loadChildren: () =>
        import('@app/client/order/routes/questions.routes').then((module) =>
          module.ROUTERS_QUESTIONS(EntityType.Callback)
        )
    },
    {
      path: 'chat-requests',
      loadChildren: () =>
        import('@app/client/order/routes/questions.routes').then((module) =>
          module.ROUTERS_QUESTIONS(EntityType.ChatRequest)
        )
    },
    {
      path: 'rfq',
      loadChildren: () => import('@app/client/order/routes/rfq.routes').then((module) => module.ROUTERS_RFQ)
    },
    {
      path: 'profile',
      component: LayoutDefaultComponent,
      data: {
        title: 'clientPage.profile.title'
      },
      children: [
        {
          path: '',
          data: {
            seo: {
              translateKey: 'profilePage'
            },
            breadcrumbs: [Crumbs.Home]
          },
          resolve: { seoData: seoResolver },
          loadComponent: () => import('@app/client/profile/profile.component').then((module) => module.ProfileComponent)
        }
      ]
    },
    { path: '', redirectTo: 'orders', pathMatch: 'full' }
  ]
};
