import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt, Sections } from '../types';
import { matcherCountry, matcherId } from '../utils';

export const lawyersRoutes: RouteExt = {
  path: 'lawyers',
  children: [
    {
      matcher: (url) => matcherCountry(url),
      component: LayoutDefaultComponent,
      data: {
        title: 'lawyersPage.title',
        isBigHeader: true
      },
      children: [
        {
          path: '',
          data: {
            section: Sections.Lawyers,
            seo: {
              translateKey: 'lawyersPage',
              canonical: true,
              manualSetTexts: true
            },
            breadcrumbs: [Crumbs.Home]
          },
          resolve: { seoData: seoResolver },
          loadComponent: () =>
            import(/* webpackPrefetch: true */ '@app/lawyers/lawyers.component').then(
              (module) => module.LawyersComponent
            )
        }
      ]
    },
    {
      path: ':id/:alias',
      component: LayoutDefaultComponent,
      data: {
        title: 'lawyersPage.title',
        isBigHeader: true,
        titleH2: true
      },
      children: [
        {
          path: '',
          data: {
            breadcrumbs: [Crumbs.Home, Crumbs.Lawyers],
            section: Sections.Lawyers,
            seo: {
              translateKey: null,
              disableAutoSetLangs: true
            }
          },
          resolve: { seoData: seoResolver },
          loadComponent: () => import('@app/lawyers/lawyer/lawyer.component').then((module) => module.LawyerComponent)
        }
      ]
    },
    {
      matcher: (url) => matcherId(url),
      redirectTo: ':id/',
      pathMatch: 'full'
    }
  ]
};
