import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt, SchemaOrgType } from '../types';

export const askALawyerRoutes: RouteExt = {
  path: 'ask-a-lawyer',
  component: LayoutDefaultComponent,
  data: {
    title: 'askALawyerPage.title',
    seo: {
      translateKey: 'askALawyerPage',
      schemaOrgKey: SchemaOrgType.WebPage,
      canonical: true
    },
    breadcrumbs: [Crumbs.Home]
  },
  resolve: { seoData: seoResolver },
  loadChildren: () =>
    import(/* webpackPrefetch: true */ '@app/ask-a-lawyer/ask-a-lawyer.module').then(
      (module) => module.AskALawyerModule
    )
};
