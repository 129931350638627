import { seoResolver } from '@shared/services/seo-resolver.service';
import { LayoutDefaultComponent } from '../layouts';
import { Crumbs, RouteExt, Sections } from '../types';
import { matcherCountry, matcherId } from '../utils';

export const lawFirmsRoutes: RouteExt = {
  path: 'law-firms',
  children: [
    {
      matcher: (url) => matcherCountry(url),
      component: LayoutDefaultComponent,
      data: {
        title: 'lawFirmsPage.title',
        isBigHeader: true
      },
      children: [
        {
          path: '',
          data: {
            section: Sections.LawFirms,
            seo: {
              translateKey: 'lawFirmsPage',
              canonical: true,
              manualSetTexts: true
            },
            breadcrumbs: [Crumbs.Home]
          },
          resolve: { seoData: seoResolver },
          loadComponent: () =>
            import(/* webpackPrefetch: true */ '@app/lawyers/lawyers.component').then(
              (module) => module.LawyersComponent
            )
        }
      ]
    },
    {
      path: ':id/:alias',
      component: LayoutDefaultComponent,
      data: {
        title: 'lawFirmsPage.title',
        isBigHeader: true,
        titleH2: true
      },
      children: [
        {
          path: '',
          data: {
            section: Sections.LawFirms,
            isFirm: true,
            breadcrumbs: [Crumbs.Home, Crumbs.Firms],
            seo: {
              translateKey: null,
              disableAutoSetLangs: true
            }
          },
          resolve: { seoData: seoResolver },
          loadComponent: () => import('@app/lawyers/lawyer/lawyer.component').then((module) => module.LawyerComponent)
        }
      ]
    },
    {
      matcher: (url) => matcherId(url),
      redirectTo: ':id/',
      pathMatch: 'full'
    }
  ]
};
